<script lang="ts" setup>
import {useSetupPagesStore} from "@/store/pageSetup/pageSetupStore";

const pageSetup = useSetupPagesStore()

function useTemplate(e){
    pageSetup.setupPage()
}
</script>

<template>
    <div class="card" style="width: 18rem;">
        <img :src="useCdnUrl('/images/template-builder/defaultTemplate.png')" class="card-img-top" alt="...">
        <div class="card-body">
            <h5 class="card-title">Base Template</h5>
            <p class="card-text">This is our fantastic template just for you!</p>
            <button class="btn btn-primary" @click="useTemplate">Use me!</button>
        </div>
    </div>
</template>

<style lang="scss">
.card {
    padding: 0px;
}
</style>