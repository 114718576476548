<template>
    <div>
        <client-only>
            <PageSetupMain v-if="
                campaignDataStore.isPageBuilder &&
                !useActualPage() &&
                useActualPageType() == homePageRef
            "></PageSetupMain>
            
        </client-only>

        <PrizificPageBuilder v-if="useActualPage()" :page="useActualPage()" />
    </div>
</template>

<script lang="ts" setup>
//{{getPage(EComponents.HOME_PAGE).getType()}}
//{{getPage(EComponents.HOME_PAGE).getComponents().map((cmp)=>{return cmp.getId()+"|"+cmp.getType()})}}
import { usePagesStore } from "@/store/pages";
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import {
    EUserValidationState,
    useUserDebugStore,
} from "@/store/components/userDebug/userDebug";
import { storeToRefs } from "pinia";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";


const homePageRef = ref(EComponents.HOME_PAGE);

const campaignDataStore = useCampaignDataStore();

// const { data: page } = await useFetch("/api/home");
//const pageStore = usePagesStore();

//const userDebugStore = useUserDebugStore();
//const isLoaded = ref(true);
</script>

<style lang="scss"></style>
