<script lang="ts" setup>
import { useSetupPagesStore } from "@/store/pageSetup/pageSetupStore";

const pageSetup = useSetupPagesStore()
</script>

<template>
    <div class="col-md-8 offset-md-2 col-12 template-setup mt-4" v-if="!pageSetup.pageSetupCompleted">
        <div v-if="!pageSetup.inProgress || pageSetup.pageSetupCompleted">
            <h1>Set up your awesome Priz<span class="changing-text-anim"></span> Game Page!</h1>
            <h3>Choose a template, what is fitting for you the best! </h3>

            <div class="row mt-4">
                <PageSetupComponentsBasePage class="col-12 col-md-4 ms-4 ml-4"></PageSetupComponentsBasePage>
                <PageSetupComponentsBasePage class="col-12 col-md-4 ms-4 ml-4"></PageSetupComponentsBasePage>
            </div>
        </div>

        <div class="row mt-4" v-if="pageSetup.inProgress">
            <PageSetupComponentsProgress class="col-12"></PageSetupComponentsProgress>
        </div>
    </div>
</template>

<style lang="scss">
.template-setup {}

.changing-text-anim {
    width: 60px;
    display: inline-block;
}

.changing-text-anim:after {
    content: "";
    animation: spin 10s linear infinite;
}

@keyframes spin {
    0% {
        content: "e";
        opacity: 0;
    }

    10% {
        content: "e";
        opacity: 1;
    }

    40% {
        content: "e";
        opacity: 1;
    }

    50% {
        content: "e";
        opacity: 0;
    }

    51% {
        content: "ific";
        opacity: 0;
    }

    60% {
        content: "ific";
        opacity: 1;
    }

    90% {
        content: "ific";
        opacity: 1;
    }



    100% {
        content: "ific";
        opacity: 0;
    }

}
</style>