<script lang="ts" setup>
import { useSetupPagesStore } from "@/store/pageSetup/pageSetupStore";

const pageSetup = useSetupPagesStore()


</script>

<template>
    <div class="row mb-4">
        <div class="col-12 mb-4">
            <h1>Setting up is in progress please wait!</h1>
            <p>After the setup is complete you will see the page!</p>
        </div>
        <div class="col-12 row" v-for="setupLog in pageSetup.setupLogs">
            <div class="col-4">{{ setupLog.getName() }}</div>
            <div class="col-8">
                <div class="progress ">

                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                        :aria-valuenow="setupLog.getProgressPercentage()" aria-valuemin="0" aria-valuemax="100"
                        :style="{ width: setupLog.getProgressPercentage() + '%' }">
                        Progress: {{setupLog.getProgressPercentage() + '%'}}
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<style lang="scss">
.card {
    padding: 0px;
}

.progress-bar {
    background-color: rgb(58, 137, 162);
}
</style>